var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import "survey-core/defaultV2.min.css";
import { Model, Survey } from "survey-react-ui";
import { setLicenseKey } from "survey-core";
import { useEffect, useState } from "react";
import { getNPS } from "./apis/nps";
import { useLocation } from "react-router-dom";
setLicenseKey("NTIwMDBjNWMtODk5My00YzE1LWExNWItYmEyY2E0OTFjZjdjOzE9MjAyNS0xMi0xMiwyPTIwMjUtMTItMTIsND0yMDI1LTEyLTEy");
export var App = function () {
    var _a;
    var _b = useState(""), data = _b[0], setData = _b[1];
    var location = useLocation(); // Obtendo o parâmetro "id" da URL
    var idLocation = (_a = location.pathname) === null || _a === void 0 ? void 0 : _a.replace(/^\/+/, ""); // Remove a barra extra no início
    useEffect(function () {
        getNPS(idLocation).then(function (result) {
            try {
                setData(JSON.parse(result.json_data));
            }
            catch (e) {
                console.error(e);
            }
        });
    }, [idLocation]);
    var survey = new Model(data);
    return (_jsxs(Grid, __assign({ container: true }, { children: [_jsx("h1", { children: "NPS Form" }), _jsx(Survey, { model: survey })] })));
};
